import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import axios from "axios";


export default function EmailVerify() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const updatedData = {
        email: email,
    };

    useEffect(() => {
        try {
            const response = axios.post(
                `${process.env.REACT_APP_API_URL}/api/users/update`,
                updatedData
            );
            //console.log(response?.data);
        } catch (error) {
            //console.log(error?.response?.data);
        }
    }, []);


    return (
        <>
            <div class="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
                <div class="max-w-xl px-5 text-center">
                    <div className='flex items-center justify-center gap-2 '>
                        <h2 class="mb-2 text-[42px] font-bold text-zinc-800">Email Verified </h2>
                        {/* shield icon */}
                        <svg stroke="green" fill="green" stroke-width="0" viewBox="0 0 24 24" height="45" width="45" xmlns="http://www.w3.org/2000/svg"><path d="M20.995 6.9a.998.998 0 0 0-.548-.795l-8-4a1 1 0 0 0-.895 0l-8 4a1.002 1.002 0 0 0-.547.795c-.011.107-.961 10.767 8.589 15.014a.987.987 0 0 0 .812 0c9.55-4.247 8.6-14.906 8.589-15.014zM12 19.897C5.231 16.625 4.911 9.642 4.966 7.635L12 4.118l7.029 3.515c.037 1.989-.328 9.018-7.029 12.264z"></path><path d="m11 12.586-2.293-2.293-1.414 1.414L11 15.414l5.707-5.707-1.414-1.414z"></path></svg>
                    </div>
                    <p class="mb-2 text-lg text-zinc-500">We are glad, that you’re with us .. Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores placeat voluptate dolor ullam voluptas rem ea quidem a aliquid earum.</p>
                    <a href="/" class="mt-3 inline-block w-96 rounded bg-indigo-600 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-indigo-700">Please Login Here →</a>
                </div>
            </div>
        </>
    )
}
