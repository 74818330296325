import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import CopyClipboard from "./CopyClipboard";
import Auth from "../utils/auth";
import auth from "../utils/auth";
import TopBarScrolling from "./TopBarScrolling";
import Dropdown from "./Dropdown";
import TokenDropdown from "./TokenDropdown";
import { Link } from "react-router-dom";

function TopNav({
  apiKey,
  setApiKey,
  selected,
  setSelected,
  cryptoName,
  tokensName,
  selectedToken,
  setSelectedToken,
  tokenDropdown,
  coinDropdown,
}) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const token = Auth.getToken();

  const userEmail = Auth.getProfile().data.email;

  useEffect(() => {
    let isMounted = true; // track whether component is mounted

    const getUser = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/me?email=${userEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (isMounted) {
          setUser(response?.data);
          setApiKey(response?.data?.apiKey);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    getUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleLogout = () => {
    auth.logout();
  };

  // Generate new key alert
  const handleGenerateNewKeyAlert = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your old key will be replaced by the new key",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleGenerateNewKey();
        Swal.fire("Success!", "New key added");
      }
    });
  };

  // Generate api key
  const handleGenerateNewKey = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/api_key?email=${userEmail}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setApiKey(response?.data);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {/* top nav */}
      <div class="px-4 py-5">
        <div class="relative flex items-center justify-between">
          <p class="inline-flex items-center">
            <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
              Blockprice.rest
            </span>
          </p>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li>
              <Link
                to="/"
                aria-label="Our product"
                title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                <button className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ">
                  Crypto
                </button>
              </Link>
            </li>
            <li>
              <Link
                to="/tokens"
                aria-label="Our product"
                title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                <button className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ">
                  Tokens
                </button>
              </Link>
            </li>
            <li>
              {user ? (
                <button
                  onClick={handleLogout}
                  type="button"
                  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                >
                  Log out
                </button>
              ) : (
                <button
                  type="button"
                  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                >
                  Log out
                </button>
              )}
            </li>
          </ul>
          <div class="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div class="absolute top-0 left-0 w-full z-30">
                <div class="p-5 bg-white border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <p class="inline-flex items-center">
                        <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                          Blockprice.rest
                        </span>
                      </p>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <Link
                          to="/crypto"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Crypto
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/tokens"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Tokens
                        </Link>
                      </li>
                      <li>
                        {user ? (
                          <button
                            onClick={handleLogout}
                            type="button"
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                          >
                            Log out
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
                          >
                            Log out
                          </button>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* top scroll bar */}
      <TopBarScrolling apiKey={apiKey} />

      <div className="flex gap-20">
        {/* dropdown for coins */}

        {coinDropdown && (
          <Dropdown
            cryptoName={cryptoName}
            selected={selected}
            setSelected={setSelected}
          />
        )}

        {/* dropdown for tokens */}
        {tokenDropdown && (
          <TokenDropdown
            tokensName={tokensName}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
          />
        )}
      </div>

      {/* api keys */}
      <div className="items-center justify-center my-6 lg:flex">
        <div className="items-center justify-center w-full lg:flex ">
          <div className="w-full max-w-[250px]">
            <label
              className="block pr-4 mb-2 font-bold text-gray-500 md:text-right md:mb-0"
              for="inline-full-name"
            >
              {loading ? (
                <span>User Name</span>
              ) : (
                <>
                  <span className="capitalize">{user?.username}</span>'s Api
                  Key:
                </>
              )}
            </label>
          </div>

          <div className="flex w-full">
            <input
              className="w-full px-4 py-2 leading-tight text-gray-700 border border-black rounded appearance-none focus:outline-none focus:bg-white focus:border-grey-500 focus:border-2"
              type="text"
              value={apiKey}
              readOnly
            />

            <CopyClipboard text={apiKey} />
          </div>
        </div>

        <button
          onClick={handleGenerateNewKeyAlert}
          type="button"
          className="max-w-[200px] w-full text-gray-900 bg-white border border-black focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 lg:ml-2 mt-3 md:mt-5 lg:mt-0"
        >
          Generate New Key
        </button>
      </div>
    </>
  );
}

export default TopNav;
