import { useEffect, useState } from "react";
import InputUrl from "../components/InputUrl";
import LatestEthHistory from "../components/LatestEthHistory";
import TopNav from "../components/TopNav";

function Main() {
  const [apiKey, setApiKey] = useState(null);
  const [timeStamp, setTimeStamp] = useState("<unix time stamp>");
  // api name
  const [apiName, setApiName] = useState(null);

  // Dropdown data
  const cryptoName = [
    { name: "ethereum" },
    { name: "binance" },
    { name: "avalanche" },
    { name: "bitcoin" },
    { name: "fantom" },
    { name: "polygon" },
    { name: "solana" },
    { name: "cardano" },
    { name: "cosmos" },
    { name: "chainlink" },
  ];

  // dropdown coin select
  const [selected, setSelected] = useState(cryptoName[0]);
  // dropdown token select

  const [colorBlink, setColorBlink] = useState(false);
  // New state for tracking first render
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (firstRender) {
      setColorBlink(true);

      // Reset colorBlink after 2 seconds
      const timeout = setTimeout(() => {
        setColorBlink(false);
      }, 2000);

      return () => clearTimeout(timeout); // Clear the timeout on unmount or when effect runs again
    }
    setFirstRender(true);
  }, [selected]);

  useEffect(() => {
    setApiName(selected.name);
  }, [selected]);

  // update api coin name
  useEffect(() => {
    setApiName(selected.name);
  }, [selected]);

  // Coin dropdown show
  const coinDropdown = true;

  return (
    <>
      <div className="container pb-14 ">
        <TopNav
          cryptoName={cryptoName}
          selected={selected}
          setSelected={setSelected}
          apiKey={apiKey}
          setApiKey={setApiKey}
          coinDropdown={coinDropdown}
        />

        {/* api urls */}
        <div className="lg:w-[86%] mt-10 mx-auto">
          {/* current price */}
          <InputUrl
            colorBlink={colorBlink}
            label={`Current ${apiName} coin Price`}
            apiUrl={`${process.env.REACT_APP_API_URL}/api/${
              apiKey || "<apiKey>"
            }/${apiName}/`}
          />

          {/* history price */}
          <InputUrl
            colorBlink={colorBlink}
            label={`History ${apiName} Price`}
            apiUrl={`${process.env.REACT_APP_API_URL}/api/${
              apiKey || "<apiKey>"
            }/${apiName}/${timeStamp}/`}
          />

          <hr className="bg-gray-200 h-[5px] w-full my-10" />

          {apiKey ? (
            <div className="flex items-start ">
              {/* <CurrentEthPrice apikey={apiKey} apiName={apiName} /> */}

              <LatestEthHistory
                setTimeStamp={setTimeStamp}
                apikey={apiKey}
                apiName={apiName}
              />
            </div>
          ) : (
            <p className="text-2xl font-semibold text-center text-red-500">
              Generate an API Key First!
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Main;
