import InputUrl from "../components/InputUrl";
import TopNav from "../components/TopNav";
import { useEffect, useState } from "react";
import LatestEthHistory from "../components/LatestEthHistory";

function AllTokens() {
  const [apiKey, setApiKey] = useState(null);

  // token api name
  const [tokenApi, setTokenApi] = useState(null);

  // Token dropdown data
  const tokensName = [
    { name: "mobox" },
    { name: "sand" },
    { name: "kryxavia" },
    { name: "ibat" },
  ];

  // dropdown token select
  const [selectedToken, setSelectedToken] = useState(tokensName[0]);

  const [tokenColorBlink, setTokenColorBlink] = useState(false);
  // New state for tracking first render
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (firstRender) {
      setTokenColorBlink(true);

      // Reset colorBlink after 2 seconds
      const timeout = setTimeout(() => {
        setTokenColorBlink(false);
      }, 2000);

      return () => clearTimeout(timeout); // Clear the timeout on unmount or when effect runs again
    }
    setFirstRender(true);
  }, [selectedToken]);

  // update api token name
  useEffect(() => {
    setTokenApi(selectedToken.name);
    // setColorBlink(true)
  }, [selectedToken]);

  // Token dropdown show
  const tokenDropdown = true;
  return (
    <>
      <div className="container pb-14 ">
        <TopNav
          tokensName={tokensName}
          apiKey={apiKey}
          setApiKey={setApiKey}
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          tokenDropdown={tokenDropdown}
        />

        {/* api urls */}
        <div className="lg:w-[86%] mt-10 mx-auto">
          {/* token price */}
          <InputUrl
            colorBlink={tokenColorBlink}
            label={`Current ${tokenApi} token Price`}
            apiUrl={`${process.env.REACT_APP_API_URL}/api/${
              apiKey || "<apiKey>"
            }/token/${tokenApi}/`}
          />
        </div>
      </div>
    </>
  );
}

export default AllTokens;
