import axios from "axios";
import React, { useEffect, useState } from "react";

const cryptoAbbreviations = {
  binance: "bnb",
  avax: "avax",
  ethereum: "eth",
  bitcoin: "btc",
  fantom: "ftm",
  polygon: "matic",
  // add other cryptocurrencies as needed
};

function LatestCryptoHistory({ apikey, setTimeStamp, apiName }) {
  const cryptoAbbreviation = cryptoAbbreviations[apiName.toLowerCase()];

  const [cryptoHistory, setCryptoHistoryPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleTimeChange = (event) => {
    const { value } = event.target;
    setSelectedTime(value);
  };

  const handleDateChange = (event) => {
    const { value } = event.target;
    setSelectedDate(value);
  };

  useEffect(() => {
    setCryptoHistoryPrice(null);
  }, [apiName]);

  const fetchCryptoHistoryPrice = async () => {
    if (!selectedDate || !selectedTime) {
      alert("Please select both a date and a time.");
      return;
    }

    const selectedDateTimeStr = selectedDate + " " + selectedTime;

    const selectedDateTime = new Date(selectedDateTimeStr);
    const selectedTimestamp = selectedDateTime.getTime();
    setTimeStamp(selectedTimestamp);

    setLoading(true);
    setError(null);
    const url = `${process.env.REACT_APP_API_URL}/api/${apikey}/${apiName}/${selectedTimestamp}`;

    try {
      const response = await axios.get(url);
      setCryptoHistoryPrice(response?.data?.document);
    } catch (error) {
      setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const cryptoHistoryDate = new Date(
    cryptoHistory?.[`${cryptoAbbreviation}UnixTimeStamp`]
  );
  const timeDifferenceInMinutes = cryptoHistory?.timeDifference / 1000 / 60;

  const cryptoHistoryTimeStr = cryptoHistoryDate?.toLocaleTimeString();
  const cryptoHistoryDateStr = cryptoHistoryDate?.toLocaleDateString();

  //console.log(cryptoHistoryTimeStr);

 //console.log(cryptoHistory?.[`${cryptoAbbreviation}HistoryPrice`]);

  return (
    <div>
      <h3 className="mb-3 text-xl font-semibold">Select Time and Date</h3>
      <div className="gap-10 md:flex">
        <div className="flex gap-10">
          <div>
            Select Time
            <br />
            <input
              type="time"
              value={selectedTime}
              onChange={handleTimeChange}
            />
          </div>
          <div>
            Select a Date
            <br />
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
        </div>

        <div>
          <button
            onClick={fetchCryptoHistoryPrice}
            className="px-3 py-2 mt-5 text-white bg-blue-700 rounded-lg md:mt-0 disabled:bg-gray-200 "
            disabled={!selectedDate || !selectedTime}
          >
            Search
          </button>
        </div>
      </div>

      <div className="mt-10">
        <h4 className="mb-5 text-2xl font-bold">
          Latest <span className="capitalize">{apiName}</span> History :
        </h4>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : cryptoHistory ? (
          <div className="space-y-5">
            <p className="text-xl font-semibold">
              {apiName} Price:{" "}
              {cryptoHistory?.[`${cryptoAbbreviation}HistoryPrice`]}
            </p>
            <p className="text-xl font-semibold">
              {apiName} history time: {cryptoHistoryTimeStr}
            </p>
            <p className="text-xl font-semibold">
              {apiName} history date: {cryptoHistoryDateStr}
            </p>
            {/* <p className="text-xl font-semibold">
              Time Difference: {parseInt(timeDifferenceInMinutes)} Minutes
            </p> */}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default LatestCryptoHistory;
