import axios from "axios";
import React, { useEffect, useState } from "react";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Slider from "react-slick";

function TopBarScrolling({ apiKey }) {
  const [data, setData] = useState([]);

  // Get all blockchain/crypto current info
  useEffect(() => {
    const getCurrentPrices = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/${apiKey}/currentPrice`
      );
      //console.log(res.data)
      setData(res.data);
    };
    getCurrentPrices();
  }, [apiKey]);

  if (!apiKey) {
    return <>Scroll bar Loading..</>;
  }

  const settings = {
    arrows: false,
    dots: false,
    pagination: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className="mt-5">
        {data?.map((blockchain) => {
          return (
            <div key={blockchain?.cryptoName}>
              <div className="flex gap-1 mx-10 font-semibold">
                <img
                  className=" w-7 h-7"
                  src={blockchain?.icon}
                  alt={blockchain?.cryptoName}
                />
                <span>{blockchain?.cryptoName}</span>
                <span>{parseFloat(blockchain?.currentPrice).toFixed(2)}</span>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default TopBarScrolling;
