import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import SignUp from "./pages/SignUp";
import auth from "./utils/auth";
import EmailVerify from "./pages/EmailVerify";
import AllTokens from "./components/AllTokens";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const token = localStorage.getItem("id_token");


  useEffect(() => {
    if (token) {
      return setIsLoggedIn(true);
    }
  }, []);

  // Function to handle successful login
  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };


  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn && !auth.isTokenExpired(token) ? (
              <Main />
            ) : (
              <Login onLoginSuccess={handleLoginSuccess} />
            )
          }
        />

        <Route path="/tokens" element={<AllTokens />} />


        <Route path="/signup" element={<SignUp />} />
        <Route path="/email_verify" element={<EmailVerify />} />
        {
          isLoggedIn && <Route path="/crypto" element={<Main />} />
        }
        {
          isLoggedIn && <Route path="/tokens" element={<Main />} />
        }



      </Routes>
    </>
  );
}

export default App;
