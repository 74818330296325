import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import auth from "../utils/auth";

const Login = ({ onLoginSuccess }) => {
  const [formState, setFormState] = useState({ email: "", password: "" });

  const [showPassword, setShowPassword] = useState(false);

  // const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  // const [currentUser, setCurrentUser] = useState({});

  // const [twoFactorAuthCode, setTwoFactorAuthCode] = useState("");
  // // set error if wrong validation code is entered
  // const [validationError, setValidationError] = useState(false);

  const [data, setData] = useState(false);
  const [error, setError] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // submit login from
  const handleLogin = async (event) => {
    event.preventDefault();

    const userData = {
      email: formState.email,
      password: formState.password,
    };

    // Login user
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/login`,
        userData
      );
      setData(response?.data);
      //console.log(response?.data)
      if (response?.data) {
        auth.login(response?.data?.token);
        // clear form values
        setError("");
        setFormState({
          email: "",
          password: "",
        });
        setTimeout(() => {
          onLoginSuccess();
        }, "1000");
      }
    } catch (error) {
      // console.log(error.response.data);
      setError(error?.response?.data);
    }
  };

  return (
    <section>
      <div className="flex items-center justify-center md:h-screen">
        <div className="w-full max-w-md p-8 space-y-3 border shadow-md rounded-xl">
          <h1 className="text-2xl font-bold text-center">Login</h1>
          {data ? (
            <p>Logged In Successfully , Redirected to Homepage</p>
          ) : (
            <form
              onSubmit={handleLogin}
              noValidate=""
              action=""
              className="space-y-6 ng-untouched ng-pristine ng-valid"
            >
              <div className="space-y-1 text-sm">
                <label htmlFor="email" className="block ">
                  Email
                </label>
                <input
                  onChange={handleChange}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="w-full px-4 py-3 border rounded-md"
                  value={formState.email}
                  required
                />
              </div>
              <div className="space-y-1 text-sm">
                <label htmlFor="password" className="block ">
                  Password
                </label>
                <div className="relative">
                  <input
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="w-full px-4 py-3 border rounded-md"
                    required
                  />
                  <span
                    onClick={handlePasswordVisibility}
                    className="absolute cursor-pointer right-2 top-3"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="px-5 py-2.5 relative rounded group font-medium text-white w-full text-center inline-block"
              >
                <span className="absolute top-0 left-0 w-full h-full rounded opacity-50 filter blur-sm bg-gradient-to-br from-purple-600 to-blue-500"></span>
                <span className="h-full w-full inset-0 absolute mt-0.5 ml-0.5 bg-gradient-to-br filter group-active:opacity-0 rounded opacity-50 from-purple-600 to-blue-500"></span>
                <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-out rounded shadow-xl bg-gradient-to-br filter group-active:opacity-0 group-hover:blur-sm from-purple-600 to-blue-500"></span>
                <span className="absolute inset-0 w-full h-full transition duration-200 ease-out rounded bg-gradient-to-br to-purple-600 from-blue-500"></span>
                <span className="relative">Login</span>
              </button>

              <p className="text-xs text-center sm:px-6">
                Don't have an account?
                <Link
                  to="/signup"
                  className="font-bold text-blue-600 hover:underline"
                >
                  Sign up
                </Link>
              </p>
            </form>
          )}
          {error && (
            <div className="p-3 my-3 text-xl font-bold text-red-600">
              {error.message}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Login;
